<template>
  <div class="px-4">
    <div class="row mx-0 mt-3 mb-3">
      <h1>List of Menus</h1>
    </div>
    <div class="cardStyle">
      <el-table
        header-row-class-name="thead-light"
        v-loading="loaders.manageMenu"
        :data="response.menus"
        style="width: 100%"
      >
        <el-table-column label="Menu" min-width="160px" align="center" label-class-name="justify-content-center" >
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              {{ scope.row.menu_id ? scope.row.sub_menu.title : scope.row.name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Status">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              <base-switch
                class="status-toggle mx-2"
                onText=""
                offText=""
                v-model="scope.row.status"
                @input="scope.row.menu_id ? updateMenu(scope.row) : updateDynamicSectionStatus(scope.row)"
              >
              </base-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <div class="d-flex" slot-scope="{ $index, row }" v-if="!row.menu_id">
            <img
              class="pointer mr-2"
              src="/img/icons/buttons/edit.svg"
              @click="showEditDynamicSectionModal($index, row)"
              alt="">
            <img
              class="pointer"
              src="/img/icons/buttons/delete.svg"
              @click="promptRemove($index, row)"
              alt="Delete"
            />
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.deleteModal"
        :modal-text="'You want to remove this Dynamic Content Section.'"
        :removeId="requestDynamicSection.sectionId"
        @onRemove="removeDynamicContentSection"
      />
    </div>
    <edit-dynamic-content-section-modal
      :show-modal="editDynamicSectionModal"
      :section-id="requestDynamicSection.sectionId"
      @hideModal="hideEditDynamicSectionModal"
      @updateDynamicSectionName="updateDynamicSectionName"
    />
  </div>
</template>

<script>
import CustomPagination from "../../Components/Pagination/CustomPagination";
import {Table, TableColumn} from "element-ui";
import EditDynamicContentSectionModal
  from "@/views/Pages/ContentSection/DynamicContentSection/EditDynamicContentSectionModal"

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")

export default {
  name: "ManageMenu",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    EditDynamicContentSectionModal,
  },
  data(){
    return {
      loaders: {
        manageMenu: false,
        deleteModal: false,
      },
      response: {
        menus : [],
      },
      request: {
        column:'',
      },
      requestDynamicSection: {
        sectionId: -1,
        listId: -1,
      },
      editDynamicSectionModal: false,
    }
  },
  watch: {
    isDynamicSectionAdded(newVal) {
      if (newVal == true) {
        this.getMenuList()
      }
    }
  },
  computed: {
    isDynamicSectionAdded() {
      return this.$store.state.dynamicContentSectionModule.isDynamicSectionAdded
    }
  },
  methods: {
    getMenuList(){
      this.loaders.manageMenu = true
      axios.get(this.$store.getters.getBaseUrl + '/api/menu-list')
        .then((response)=> {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.response.menus = response.data.data;
          this.response.menus.forEach(el => el.status = !!el.status)
        })
        .catch((error)=>console.log(error.response))
        .finally(()=>this.loaders.manageMenu = false)
    },
    updateMenu(subMenuItem){
      const temp = subMenuItem;
      let vm = this;
      vm.loaders.manageMenu = true;
      axios.post(this.$store.getters.getBaseUrl + `/api/menu-manage/update`,{subMenuId:subMenuItem.id,status:subMenuItem.status})
        .then((response)=> {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          this.$notify.success({
            title: 'Success',
            message: 'Menu Updated Successfully'
          })
          this.$store.getters.getSidebarMenuListFromSubMenuManagers.forEach(function(value,key) {
            if (value.id == subMenuItem.id) {
              vm.$store.commit('setSidebarMenuStatus', subMenuItem.id);
            }
          });
        })
        .catch(() => {
          this.$notify.error({
            title: 'Unable to update',
            message: 'Something went wrong please try again later'
          })
          this.$set(subMenuItem,'status',!temp.status);
        })
        .finally(() => {
          vm.loaders.manageMenu = false;
        });
    },

    /**
     * Update dynamic content section status
     * @param dynamicSection
     */
    updateDynamicSectionStatus(dynamicSection) {
      let vm = this
      vm.$store.dispatch('dynamicContentSectionModule/_updateStatusDynamicContentSection', dynamicSection)
        .then(response => {
          vm.$store.commit('dynamicContentSectionModule/setIsDynamicSectionStatusChanged', true)
          vm.$notify.success({
            title: 'Success',
            message: 'Menu Updated Successfully'
          })
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Dynamic Content Section',
            message: message
          })
        })
        .finally(() => {
          vm.$store.commit('dynamicContentSectionModule/setIsDynamicSectionStatusChanged', false)
        })
    },

    /**
     *
     * @param id
     */
    removeDynamicContentSection(id) {
      let vm = this
      vm.loaders.deleteModal = true
      vm.$store.dispatch('dynamicContentSectionModule/_deleteDynamicContentSection', {id})
      .then(response => {
        vm.$store.commit('dynamicContentSectionModule/setIsDynamicSectionStatusChanged', true)
        vm.$store.commit("hideRemoveModal")
        vm.resetDynamicSectionStats()
        vm.$notify.success({
          title: 'Success',
          message: 'Dynamic content section deleted successfully.'
        })
      })
      .catch(error => {
        const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Dynamic Content Section',
          message: message
        })
      })
      .finally(() => {
        vm.$store.commit('dynamicContentSectionModule/setIsDynamicSectionStatusChanged', false)
        vm.loaders.deleteModal = false
      })
    },

    /**
     * Reset and remove deleted section from list
     */
    resetDynamicSectionStats() {
      this.response.menus.splice(this.requestDynamicSection.listId, 1)
      this.requestDynamicSection.sectionId = -1
      this.requestDynamicSection.listId = -1
    },

    /**
     * Prompt the Remove modal
     * @param index
     * @param row
     */
    promptRemove(index, row) {
      this.requestDynamicSection.listId = index
      this.$store.commit("showRemoveModal")
      this.requestDynamicSection.sectionId = row.id
    },

    /**
     * Show edit dynamic content section modal
     */
    showEditDynamicSectionModal(index, row) {
      this.requestDynamicSection.sectionId = row.id
      this.requestDynamicSection.listId = index
      this.editDynamicSectionModal = true
    },

    /**
     * Hide edit dynamic content section modal
     */
    hideEditDynamicSectionModal() {
      this.requestDynamicSection.sectionId = -1
      this.requestDynamicSection.listId = -1
      this.editDynamicSectionModal = false
    },

    /**
     * Update the dynamic content section name
     * @param name
     */
    updateDynamicSectionName(name) {
      this.response.menus[this.requestDynamicSection.listId].name = name
    },
  },
  mounted() {
    this.getMenuList()
  }
}
</script>

<style scoped>

</style>
