<template>
  <modal @close="handleClose" :show.sync="isModalOpened" v-loading="loader">
    <h1 slot="header" class="modal-title pt-2 pl-1">Edit Dynamic Content Section</h1>
    <validation-observer v-slot="{handleSubmit}" ref="formDynamicSectionValidator">
      <form role="form" ref="formDynamicSection" @submit.prevent="handleSubmit(onFormSubmit)">
        <div class="mt--4">
          <base-input
            class=""
            :rules="{required: true}"
            name="Name"
            label="Name*"
            v-model="dynamicSection.name"
            placeholder="Name">
          </base-input>
        </div>
        <div class="text-right mt-4">
          <base-button class="cancel-btn" type="secondary" @click="handleClose">Cancel</base-button>
          <base-button class="btn-primary-action pl-5 pr-5 br-4" native-type="submit" type="success">Update</base-button>
        </div>
      </form>
    </validation-observer>
  </modal>
</template>

<script>
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "EditDynamicContentSectionModal",
  props: ['showModal', 'sectionId'],
  data() {
    return {
      isModalOpened: false,
      loader: false,
      dynamicSection: {
        id: 0,
        name: '',
      },
    }
  },
  watch: {
    showModal(newVal) {
      this.isModalOpened = newVal
    },

    sectionId(newVal) {
      if (newVal > 0) {
        this.dynamicSection.id = newVal
        this.getDynamicContentSection()
      }
    },
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    /**
     * Hide modal event
     */
    hideModal() {
      this.$emit('hideModal')
    },

    /**
     * Handle form submit event and validate form
     * @returns {Promise<void>}
     */
    async onFormSubmit() {
      const isDynamicSectionFormValid = await this.$refs['formDynamicSectionValidator'].validate()
      if (isDynamicSectionFormValid) {
        this.updateDynamicSection()
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        })
      }
    },

    /**
     * Create dynamic section
     */
    updateDynamicSection() {
      let vm = this
      vm.loader = true
      vm.$store.dispatch('dynamicContentSectionModule/_updateDynamicSection', vm.dynamicSection)
        .then(response => {
          this.$emit('updateDynamicSectionName', vm.dynamicSection.name)
          vm.$notify.success({
            title: 'Success',
            message: 'Dynamic content section updated successfully.'
          })
          vm.$store.commit('dynamicContentSectionModule/setIsDynamicSectionStatusChanged', true)
          this.handleClose()
        })
        .catch(error => {
          const message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Dynamic Section',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.$store.commit('dynamicContentSectionModule/setIsDynamicSectionStatusChanged', false)
          vm.loader = false
        })
    },

    /**
     * Handle close event
     */
    handleClose() {
      this.resetForm()
      this.hideModal()
    },

    /**
     * Reset Form fields
     */
    resetForm() {
      this.dynamicSection.name = ''
      this.dynamicSection.id = 0
      this.$refs.formDynamicSectionValidator.reset()
    },

    /**
     * Get requested dynamic content section
     */
    getDynamicContentSection() {
      let vm = this
      vm.loaders = true
      const params = {id: vm.dynamicSection.id}

      vm.$store.dispatch('dynamicContentSectionModule/_getRequestDynamicContentSection', params)
        .then(response => {
          vm.dynamicSection = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Dynamic Content Section',
            message: message
          })
        })
        .finally(() => {
          vm.loaders = false
        })
    },
  }
}
</script>

<style scoped>

</style>
